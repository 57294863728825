<template>
  <b-modal
    v-model="isVisible"
    :size="loadingContract ? 'sm' : 'xl'"
    :title="modalHeader"
    @hidden="hide()"
    hide-footer
    no-close-on-backdrop
  >
    <div id="contract-editor-modal-container">
      <Busy v-if="loadingContract" primary :text="'Loading contract'" />
      <div v-else class="mx-2">
        <ContractInfo
          :contract="contract"
          @autofill="autofillAllFields"
          @saveContract="saveContract"
        />

        <b-row>
          <b-col cols="6">
            <b-form-group label="Old Xero ID" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <font-awesome-icon
                    v-b-popover.hover="
                      'If this contract was never part of the old spreadsheet, please ignore this field.'
                    "
                    :icon="['fas', 'info-circle']"
                  ></font-awesome-icon>
                </template>
                <b-input
                  :class="checkUTD('oldXeroId')"
                  :disabled="!isEmpty(supply)"
                  v-model="contract.oldXeroId"
                ></b-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Product" label-cols-xl="4">
              <b-select
                :disabled="!isEmpty(supply) && !isEmpty(supply.type)"
                v-model="contract.product"
                :options="products"
                :class="checkUTD('product')"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Select Product Type</b-form-select-option
                  >
                </template>
              </b-select>
            </b-form-group>

            <b-form-group label="Organisation ID" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingOrganisation" :size="1" />

                  <font-awesome-icon
                    v-else-if="orgIdError"
                    v-b-popover.hover="'Error fetching data. ID might not exist.'"
                    :icon="['fas', 'info-circle']"
                    class="error-icon"
                  ></font-awesome-icon>

                  <font-awesome-icon
                    v-else-if="contract.organisationId"
                    @click="getOrganisation(contract.organisationId, true)"
                    v-b-popover.hover="'Autofill organisation & accountant details'"
                    :icon="['fas', 'magnet']"
                    class="clickable"
                  ></font-awesome-icon>
                </template>

                <b-form-input
                  @input="orgIdError = false"
                  v-model="contract.organisationId"
                  :disabled="loadingOrganisation || !isEmpty(deal && deal.organisationId)"
                  :class="checkUTD('organisationId')"
                ></b-form-input
              ></b-input-group>
            </b-form-group>

            <b-form-group label="Organisation Name" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingOrganisation" :size="1" />
                </template>

                <b-form-input
                  v-model="contract.organisationName"
                  :disabled="loadingOrganisation"
                  :class="checkUTD('organisationName', organisation.name)"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Org. Created Date" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingOrganisation" :size="1" />
                </template>

                <b-form-input
                  :disabled="loadingOrganisation"
                  v-model="contract.organisationCreatedDate"
                  type="date"
                  :class="
                    checkUTD(
                      'organisationCreatedDate',
                      !isEmpty(organisation.createdDate)
                        ? organisation.createdDate.slice(0, 10)
                        : null,
                      true
                    )
                  "
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Accountant User ID" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy :size="1" v-if="loadingUser || loadingOrganisation" />

                  <font-awesome-icon
                    v-else-if="accIdError"
                    v-b-popover.hover="'Error fetching data. ID might not exist.'"
                    :icon="['fas', 'info-circle']"
                    class="error-icon"
                  ></font-awesome-icon>

                  <font-awesome-icon
                    class="clickable"
                    v-else-if="!isEmpty(contract.accountantUserId) || !isEmpty(accountant.userId)"
                    @click="getUser(contract.accountantUserId || accountant.userId, true)"
                    v-b-popover.hover="'Autofill accountant details'"
                    :icon="['fas', 'magnet']"
                  ></font-awesome-icon>

                  <font-awesome-icon
                    v-else
                    class="input-append"
                    v-b-popover.hover="
                      'Autofill needs existing accountant ID. Try autofilling organisation.'
                    "
                    :icon="['fas', 'info-circle']"
                  ></font-awesome-icon>
                </template>

                <b-form-input
                  @change="accIdError = false"
                  :class="checkUTD('accountantUserId', accountant.userId)"
                  v-model="contract.accountantUserId"
                  :disabled="loadingUser || loadingOrganisation"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Accountant Sign-Up" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingUser || loadingOrganisation" :size="1" />
                </template>

                <b-form-input
                  :class="
                    checkUTD(
                      'accountantSignUpDate',
                      !isEmpty(accountant.createdDate) ? accountant.createdDate.slice(0, 10) : null,
                      true
                    )
                  "
                  :disabled="loadingUser || loadingOrganisation"
                  v-model="contract.accountantSignUpDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Accountant Firm" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy :size="1" v-if="loadingUser || loadingOrganisation" />
                </template>
                <b-form-input
                  :class="checkUTD('accountantFirm', accountant.practiceName)"
                  v-model="contract.accountantFirm"
                  :disabled="loadingUser || loadingOrganisation"
                ></b-form-input></b-input-group
            ></b-form-group>

            <b-form-group label="Accountant Email" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy :size="1" v-if="loadingUser || loadingOrganisation" />
                </template>
                <b-form-input
                  :class="checkUTD('accountantEmail', accountant.email)"
                  v-model="contract.accountantEmail"
                  :disabled="loadingUser || loadingOrganisation"
                ></b-form-input></b-input-group
            ></b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              label="Deal Id"
              :disabled="!isEmpty(deal && deal.dealId)"
              label-cols-xl="4"
            >
              <b-form-input :class="checkUTD('dealId')" v-model="contract.dealId"></b-form-input
            ></b-form-group>

            <b-form-group
              label="Supply ID"
              label-cols-xl="4"
              :disabled="!isEmpty(supply && supply.supplyId)"
            >
              <b-form-input :class="checkUTD('supplyId')" v-model="contract.supplyId"></b-form-input
            ></b-form-group>

            <b-form-group label="Supply Name" label-cols-xl="4">
              <b-form-input
                v-model="contract.supplyName"
                :class="checkUTD('supplyName', supply ? supply.name : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Old Supplier ID" label-cols-xl="4">
              <b-form-input
                v-model="contract.oldSupplierId"
                :class="
                  checkUTD(
                    'oldSupplierId',
                    !isEmpty(supply && supply.currentDeal) ? supply.currentDeal.supplierId : null
                  )
                "
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Old Supplier Name" label-cols-xl="4">
              <b-form-input
                v-model="contract.oldSupplierName"
                :class="
                  checkUTD(
                    'oldSupplierName',
                    !isEmpty(supply && supply.currentDeal) ? supply.currentDeal.supplierName : null
                  )
                "
              ></b-form-input
            ></b-form-group>

            <b-form-group label="New Supplier" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingSuppliers" :size="1" />
                </template>
                <b-form-select
                  @input="setNewSupplierFields"
                  v-model="contract.newSupplierId"
                  :options="Object.values(suppliers)"
                  value-field="supplierId"
                  text-field="name"
                  :class="checkUTD('newSupplierId', deal ? deal.supplierId : null)"
                  :disabled="loadingSuppliers"
                ></b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Counterparty" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="loadingSuppliers" :size="1" />
                </template>
                <b-form-select
                  @input="setCounterPartyFields"
                  v-model="contract.paymentFromId"
                  :options="Object.values(brokers)"
                  value-field="supplierId"
                  text-field="name"
                  :class="checkUTD('paymentFromId')"
                  :disabled="loadingSuppliers"
                ></b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Meter Number" label-cols-xl="4">
              <b-form-input
                v-model="contract.meterNumber"
                :class="checkUTD('meterNumber', supply ? supply.meterNumber : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="CPM" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="cpmsBusy" :size="1" />
                </template>
                <b-form-select
                  @input="setCpmFields"
                  v-model="contract.cpmId"
                  :options="cpms"
                  value-field="userId"
                  text-field="email"
                  :disabled="cpmsBusy || salesRecordBusy"
                ></b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="APM" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="apmsBusy" :size="1" />
                </template>
                <b-form-select
                  @input="setApmFields"
                  v-model="contract.apmId"
                  :options="apms"
                  value-field="userId"
                  text-field="email"
                  :disabled="apmsBusy || salesRecordBusy"
                ></b-form-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Ops" label-cols-xl="4">
              <b-input-group class="appended-input-group">
                <template #append>
                  <Busy v-if="opsBusy" :size="1" />
                </template>
                <b-form-select
                  @input="setOpsFields"
                  v-model="contract.opsId"
                  :options="ops"
                  value-field="userId"
                  text-field="email"
                  :disabled="opsBusy || salesRecordBusy"
                ></b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col cols="6">
            <b-form-group label="Contract Length" label-cols-xl="4">
              <b-input-group>
                <template #append>
                  <b-input-group-text>months</b-input-group-text>
                </template>
                <b-input
                  @input="
                    generateSavingsOverContract();
                    generateContractEndDate();
                  "
                  type="number"
                  v-model="contract.contractLength"
                  :class="checkUTD('contractLength', deal ? deal.contractTerm : null)"
                ></b-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Start Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  @change="generateContractEndDate"
                  :class="checkUTD('startDate', null, true)"
                  v-model="contract.startDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="End Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  :class="checkUTD('endDate', null, true)"
                  v-model="contract.endDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Out Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  @change="stateChange"
                  :class="checkUTD('outDate', null, true)"
                  v-model="contract.outDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Signed Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  :class="checkUTD('signedDate', null, true)"
                  @change="stateChange"
                  v-model="contract.signedDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Re-signed Date" label-cols-xl="4">
              <div
                v-for="(resignedDate, index) in contract.resignedDates"
                :key="'resigned-date' + index + resignedDate"
                class="contract-lists pl-3 py-1 mb-2"
                :class="checkUnsavedList(resignedDate, 'resignedDates')"
              >
                <p class="m-0">{{ timestampFromDate(formatDateFullYear(resignedDate)) }}</p>
                <b-button
                  variant="outline-scondary"
                  size="sm"
                  class="mr-2"
                  @click="removeResignedDate(index)"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" size="sm" />
                </b-button>
              </div>

              <div class="d-flex">
                <b-form-input
                  type="date"
                  v-model="newResignedDate"
                  :class="checkUnsavedList(newResignedDate, 'resignedDates')"
                ></b-form-input
                ><b-button variant="primary" :disabled="!newResignedDate" @click="addResignedDate">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Locked-in Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  @change="stateChange"
                  :class="checkUTD('lockedinDate', null, true)"
                  v-model="contract.lockedinDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Objection Date" label-cols-xl="4">
              <div
                v-for="(objectionDate, index) in contract.objectionDates"
                :key="'objection-date' + index + objectionDate"
                class="contract-lists pl-3 py-1 mb-2"
                :class="checkUnsavedList(objectionDate, 'objectionDates')"
              >
                <p class="m-0">{{ timestampFromDate(formatDateFullYear(objectionDate)) }}</p>
                <b-button
                  variant="outline-scondary"
                  size="sm"
                  class="mr-2"
                  @click="removeObjectionDate(index)"
                >
                  <font-awesome-icon :icon="['fas', 'times-circle']" size="sm" />
                </b-button>
              </div>

              <div class="d-flex">
                <b-form-input
                  type="date"
                  v-model="newObjectionDate"
                  :class="checkUnsavedList(newObjectionDate, 'objectionDates')"
                ></b-form-input
                ><b-button
                  variant="primary"
                  :disabled="!newObjectionDate"
                  @click="addObjectionDate"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </b-button>
              </div>
            </b-form-group>

            <b-form-group label="Failed Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  @change="stateChange"
                  :class="checkUTD('failedDate', null, true)"
                  v-model="contract.failedDate"
                  type="date"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Reason Failed" v-if="contract.failedDate" label-cols-xl="4">
              <b-form-input
                :class="checkUTD('reasonFailed')"
                v-model="contract.reasonFailed"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Dead Date" label-cols-xl="4">
              <b-input-group>
                <b-form-input
                  :class="checkUTD('deadDate', null, true)"
                  @change="stateChange"
                  v-model="contract.deadDate"
                  type="date"
                  placeholder="YYYY-MM-DD"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Reason Dead" v-if="contract.deadDate" label-cols-xl="4">
              <b-form-input
                v-model="contract.reasonDead"
                :class="checkUTD('reasonDead')"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="State" label-cols-xl="4">
              <p class="d-none">{{ contract.state }}</p>
              <b-select v-model="contract.state" :class="checkUTD('state')">
                <b-form-select-option :value="null" disabled>Select State</b-form-select-option>
                <b-form-select-option :value="'NEW'">NEW</b-form-select-option>
                <b-form-select-option :value="'OUT'">OUT</b-form-select-option>
                <b-form-select-option :value="'SIGNED'">SIGNED</b-form-select-option>
                <b-form-select-option :value="'LOCKED_IN'">LOCKED_IN</b-form-select-option>
                <b-form-select-option :value="'FAILED'">FAILED</b-form-select-option>
                <b-form-select-option :value="'DEAD'">DEAD</b-form-select-option>
                <b-form-select-option :value="'LIVE'">LIVE</b-form-select-option>
              </b-select></b-form-group
            >

            <b-form-group label="Docusign URL" label-cols-xl="4">
              <div
                class="contract-lists pl-3 py-1 mb-2"
                v-for="(url, index) in contract.docusignContractURL"
                :key="index + url"
                :class="checkUnsavedList(url, 'docusignContractURL')"
              >
                <p class="m-0">{{ url }}</p>
                <b-button variant="outline-secondary" size="sm" @click="removeDocusignURL(index)">
                  <font-awesome-icon :icon="['fas', 'times-circle']" />
                </b-button>
              </div>
              <div class="d-flex">
                <b-form-input
                  :class="checkUnsavedList(docusignContractURL, 'docusignContractURL')"
                  v-model="docusignContractURL"
                ></b-form-input
                ><b-button
                  variant="primary"
                  :disabled="!docusignContractURL"
                  @click="addDocusignContractURL"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </b-button>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Consumption" label-cols-xl="4">
              <b-form-input
                type="number"
                v-model="contract.consumption"
                :class="checkUTD('consumption', supply ? supply.dayConsumption : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Commission Level" label-cols-xl="4">
              <b-form-input
                type="number"
                v-model="contract.commissionLevel"
                :class="checkUTD('commissionLevel', deal ? deal.commission : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Commission Gross" label-cols-xl="4">
              <b-form-input
                type="number"
                v-model="contract.commissionGross"
                @input="commissionGrossChange;"
                step=".01"
                :class="checkUTD('commissionGross', deal ? deal.consumptionCommission : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Commission Net" label-cols-xl="4">
              <b-input-group class="d-flex">
                <template #append>
                  <b-input-group-text>% of gross</b-input-group-text>
                </template>
                <b-form-input
                  @input="commissionNetChange;"
                  :class="checkUTD('commissionNet')"
                  type="number"
                  class="mr-2"
                  step=".01"
                  v-model="contract.commissionNet"
                ></b-form-input>
                <b-form-input
                  type="number"
                  step=".01"
                  @input="commissionGrossChange"
                  v-model="commissionNetPercetage"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <div class="outline-box mb-3">
              <div class="d-flex justify-content-between">
                <span class="pl-3 pt-1">
                  CHURN VALUE
                  <font-awesome-icon
                    class="menu-icon"
                    :icon="['far', 'question-circle']"
                    v-b-tooltip.hover="
                      'Recording of initial comms if they change after a contract already has been created'
                    "
                  />
                </span>

                <b-button
                  size="sm"
                  class="outline-box-button"
                  variant="outline-secondary"
                  v-b-tooltip.hover="'Move the existing values into churn'"
                  @click="churnMove"
                >
                  <font-awesome-icon class="menu-icon" :icon="['fas', 'arrow-circle-down']" />
                </b-button>
              </div>

              <div class="px-3 pt-3">
                <b-form-group label="Initial Consumption" label-cols-xl="5">
                  <b-form-input type="number" v-model="contract.initialConsumption"></b-form-input
                ></b-form-group>

                <b-form-group label="Initial Commission Level" label-cols-xl="5">
                  <b-form-input
                    type="number"
                    v-model="contract.initialCommissionLevel"
                  ></b-form-input
                ></b-form-group>

                <b-form-group label="Initial Commission Gross" label-cols-xl="5">
                  <b-form-input
                    type="number"
                    v-model="contract.initialCommissionGross"
                  ></b-form-input
                ></b-form-group>
              </div>
            </div>

            <b-form-group label="Old Annual Cost" label-cols-xl="4">
              <b-form-input
                type="number"
                v-model="contract.oldAnnualCost"
                :class="
                  checkUTD(
                    'oldAnnualCost',
                    !isEmpty(supply && supply.contractcurrentDealStartDate)
                      ? supply.currentDeal.annualCost
                      : null
                  )
                "
              ></b-form-input
            ></b-form-group>

            <b-form-group label="New Annual Cost" label-cols-xl="4">
              <b-form-input
                type="number"
                v-model="contract.newAnnualCost"
                :class="checkUTD('newAnnualCost', deal ? deal.annualCost : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Annual Savings" label-cols-xl="4">
              <b-form-input
                @input="generateSavingsOverContract;"
                type="number"
                v-model="contract.annualSavings"
                :class="checkUTD('annualSavings', deal ? deal.saving : null)"
              ></b-form-input
            ></b-form-group>

            <b-form-group label="Savings Over Contract" label-cols-xl="4">
              <b-form-input
                type="number"
                :class="checkUTD('savingsOverContract')"
                v-model="contract.savingsOverContract"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="outline-box mb-3">
          <div class="d-flex justify-content-between">
            <span class="pl-3 pt-1">
              DEAL RATES
              <font-awesome-icon
                class="menu-icon"
                :icon="['far', 'question-circle']"
                v-b-tooltip.hover="'These rates are here for reference purposes'"
              />
            </span>

            <b-button-group>
              <b-button
                size="sm"
                class="outline-box-button"
                variant="outline-secondary"
                v-b-tooltip.hover="'Allow editing the rates'"
                @click="editableRates = !editableRates"
              >
                <font-awesome-icon v-if="!editableRates" :icon="['fas', 'lock-alt']" />
                <font-awesome-icon v-else :icon="['fas', 'lock-open-alt']" />
              </b-button>
              <b-button
                size="sm"
                class="outline-box-button"
                variant="outline-secondary"
                v-b-tooltip.hover="'Pull rates from the connected deal'"
                @click="autofillDealExtraFields(true)"
              >
                <font-awesome-icon :icon="['fas', 'magnet']" />
              </b-button>
            </b-button-group>
          </div>

          <b-row class="mt-2 px-3">
            <b-col cols="6">
              <b-form-group label="Standing Charge" label-cols-xl="4">
                <b-form-input
                  :disabled="!editableRates"
                  :class="checkUTD('standingCharge', deal ? deal.standingCharge : null)"
                  v-model="contract.standingCharge"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Day Rate" label-cols-xl="4">
                <b-input
                  :disabled="!editableRates"
                  :class="checkUTD('dayRate', deal ? deal.dayUnitRate : null)"
                  v-model="contract.dayRate"
                ></b-input>
              </b-form-group>

              <b-form-group label="Night Rate" label-cols-xl="4">
                <b-input
                  :disabled="!editableRates"
                  :class="checkUTD('nightRate', deal ? deal.nightUnitRate : null)"
                  v-model="contract.nightRate"
                ></b-input>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="EW Rate" label-cols-xl="4">
                <b-input
                  :disabled="!editableRates"
                  :class="checkUTD('ewRate', deal ? deal.ewUnitRate : null)"
                  v-model="contract.ewRate"
                ></b-input>
              </b-form-group>

              <b-form-group label="Winter Rate" label-cols-xl="4">
                <b-input
                  :disabled="!editableRates"
                  :class="checkUTD('winterRate', deal ? deal.winterUnitRate : null)"
                  v-model="contract.winterRate"
                ></b-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <ContractComments :contractId="contractId" v-if="!loadingContract" />

        <div class="text-right mt-3">
          <b-button variant="outline-danger" @click="hide()" class="mx-1"> Close </b-button>
          <b-button
            variant="outline-secondary"
            class="mx-1"
            v-if="deal || supply"
            @click="autofillAllFields"
          >
            Autofill fields
          </b-button>
          <b-button @click="saveContract" variant="primary" class="mx-1"> Save </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#contract-editor-modal-container {
  .data-unsaved {
    border-bottom: 2px solid $color-blue-main !important;
  }

  .changes-detected {
    border-bottom: 2px solid $color-yellow-red2;
  }

  .outline-box {
    border-radius: 10px;
    border: 1px solid $color-grey-lighter2;

    .outline-box-button {
      padding: 2px 8px 2px 8px !important;
    }
  }

  .appended-input-group {
    .input-group-append {
      display: flex;
      align-items: center;
      border: 1px solid $color-grey-lighter2;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      & > .clickable {
        cursor: pointer;
      }
      & > svg {
        margin: auto 0.5rem auto 0.5rem;
        color: $color-grey;
        &.error-icon {
          color: $color-red-light1 !important;
        }
      }
      & > .busy {
        margin: auto 0.5rem auto 0.5rem !important;
      }
    }
  }

  .contract-lists {
    background-color: $color-grey-lighter4;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn,
    .btn:hover {
      background-color: none !important;
      border: none !important;
      color: $color-grey-lighter1;
    }
  }
}
</style>

<script>
import ContractInfo from "@/components/contracts/ContractInfo";
import ContractComments from "@/components/contracts/ContractComments";
import Busy from "@/components/Busy";
import SupplyTypes from "@/helper/supplytypehelper";
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";
import Static from "@/components/savingseditor/helpers/statichelper";

export default {
  name: "ContractEditor",
  props: {
    contractId: {
      type: String,
      default: null
    },
    supply: {
      type: Object,
      default: null
    },
    deal: {
      type: Object,
      default: null
    }
  },
  components: { Busy, ContractInfo, ContractComments },
  data() {
    return {
      adminId: null,
      loadingContract: false,
      loadingOrganisation: false,
      orgIdError: false,
      loadingUser: false,
      accIdError: false,
      loadingSuppliers: false,
      supplierError: false,
      isVisible: false,
      commissionNetPercetage: 85,
      newResignedDate: null,
      newObjectionDate: null,
      contract: { docusignContractURL: [], resignedDates: [], objectionDates: [] },
      savedContract: { docusignContractURL: [], resignedDates: [], objectionDates: [] },
      organisation: {},
      accountant: {},
      products: [],
      dateFields: [
        "startDate",
        "signedDate",
        "lockedinDate",
        "endDate",
        "outDate",
        "failedDate",
        "deadDate",
        "organisationCreatedDate",
        "accountantSignUpDate"
      ],
      docusignContractURL: null,
      editableRates: false,
      suppliers: [],
      apmsBusy: false,
      cpmsBusy: false,
      opsBusy: false,
      cpms: [],
      apms: [],
      ops: []
    };
  },
  computed: {
    modalHeader: function() {
      if (!this.loadingContract) {
        return this.contract.contractId ? "Update existing contract" : "Create new contract";
      }
      return "";
    },
    brokers() {
      //simple selection by putting brokers at the top
      var brokers = [];
      // first add brokers
      for (var supplierId in this.suppliers) {
        if (this.suppliers[supplierId]["broker"]) {
          brokers.push(this.suppliers[supplierId]);
        }
      }
      // then add all suppliers, including the brokers again
      for (var supplierId2 in this.suppliers) {
        brokers.push(this.suppliers[supplierId2]);
      }
      return brokers;
    }
  },
  created() {
    this.contract.startDate = new Date(new Date().setDate(new Date().getDate() + 1))
      .toISOString()
      .slice(0, 10);

    this.products = [...this.getProducts()];
    this.fetchCpmUsers();
    this.fetchApmUsers();
    this.fetchOpsUsers();
  },
  methods: {
    isEmpty: FormatHelper.isEmpty,
    supplyTypeDisplay: SupplyTypes.toDisplay,
    formatDateFullYear: FormatHelper.formatDateFullYear,
    formatDateStringWithTime: FormatHelper.formatDateStringWithTime,

    async show(id = null, editURL = null) {
      this.accIdError = false;
      this.orgIdError = false;

      this.organisation = {};
      this.accountant = {};
      this.comments = [];
      this.newComment = {
        content: null,
        contractId: this.contractId
      };

      this.newResignedDate = null;
      this.newObjectionDate = null;

      this.contract = {
        contractId: id,
        oldXeroId: null,
        product: null,
        organisationId: null,
        organisationName: null,
        organisationCreatedDate: null,
        accountantUserId: null,
        accountantSignUpDate: null,
        accountantFirm: null,
        accountantEmail: null,
        dealId: null,
        supplyId: null,
        supplyName: null,
        oldSupplierId: null,
        oldSupplierName: null,
        newSupplierId: null,
        newSupplierName: null,
        paymentFromId: null,
        paymentFromName: null,
        meterNumber: null,
        contractLength: null,
        startDate: null,
        endDate: null,
        signedDate: null,
        lockedinDate: null,
        deadDate: null,
        failedDate: null,
        outDate: null,
        state: "NEW",
        consumption: null,
        commissionLevel: null,
        commissionGross: null,
        commissionNet: null,
        oldAnnualCost: null,
        newAnnualCost: null,
        annualSavings: null,
        savingsOverContract: null,
        reasonFailed: null,
        reasonDead: null,
        docusignContractURL: [],
        resignedDates: [],
        objectionDates: [],
        standingCharge: null,
        dayRate: null,
        nightRate: null,
        ewRate: null,
        winterRate: null,
        initialCommissionLevel: null,
        initialCommissionGross: null,
        initialConsumption: null,
        cpmId: null,
        cpmEmail: null,
        apmId: null,
        apmEmail: null
      };
      this.savedContract = Object.assign({}, this.contract);

      if (editURL) {
        this.contract.docusignContractURL = [editURL];
        this.contract.outDate = new Date().toISOString().slice(0, 10);
      }

      this.isVisible = true;

      await this.fetchAllData();

      if (this.isEmpty(this.contract.contractId)) {
        this.autofillAllFields();
      }

      this.autofillDealExtraFields();
      this.commissionNetChange();
    },
    hide() {
      this.isVisible = false;
    },

    checkUnsavedList(fieldValue, listName) {
      //add id with the contract field name to each input
      let savedContrList = this.savedContract[listName];
      if (!this.isEmpty(fieldValue)) {
        if (this.isEmpty(savedContrList) || !savedContrList.includes(fieldValue)) {
          return " data-unsaved";
        }
      }

      return "";
    },

    checkUnsaved(contrFieldName, isDate = false) {
      //add id with the contract field name to each input
      let contrField = this.contract[contrFieldName];
      let savedContrField = this.savedContract[contrFieldName];

      contrField = this.isEmpty(contrField) ? null : contrField;
      savedContrField = this.isEmpty(savedContrField) ? null : savedContrField;

      if (isDate && savedContrField) {
        savedContrField = this.timestampFromDate(savedContrField);
      }

      if (contrField != savedContrField) {
        return true;
      } else if (contrField == savedContrField) {
        return false;
      }
    },

    checkUTD(contrFieldName, seField = null, isDate = false) {
      /* Checks if contract fields are *up to date* with the latest data in the system

        contrFieldName : contract field name
        seField : Savings editor field
        returns a class name
      */

      let result = "";
      let contrField = this.contract[contrFieldName];

      if (this.contract.contractId) {
        if (
          (this.isEmpty(contrField) && !this.isEmpty(seField)) ||
          (!this.isEmpty(contrField) && !this.isEmpty(seField) && contrField != seField)
        ) {
          result = " changes-detected";
        }
      }

      if (this.checkUnsaved(contrFieldName, isDate)) {
        result += " data-unsaved";
      }

      return result;
    },

    churnMove() {
      if (!this.isEmpty(this.contract)) {
        if (this.contract.consumption) {
          this.contract.initialConsumption = this.contract.consumption;
          this.contract.consumption = null;
        }

        if (this.contract.commissionLevel) {
          this.contract.initialCommissionLevel = this.contract.commissionLevel;
          this.contract.commissionLevel = null;
        }

        if (this.contract.commissionGross) {
          this.contract.initialCommissionGross = this.contract.commissionGross;
          this.contract.commissionGross = null;
        }

        if (
          this.isEmpty(this.contract.commissionGross) &&
          this.isEmpty(this.contract.commissionLevel) &&
          this.isEmpty(this.contract.consumption)
        ) {
          this.contract.commissionNet = null;
          this.commissionNetPercetage = null;
        }
      }
    },

    //APIS
    getProducts() {
      let result = [];
      let productsArr = SupplyTypes.supplyTypes();
      if (!this.isEmpty(productsArr)) {
        productsArr.forEach(product => {
          let obj = {};
          obj.value = product;
          obj.text = this.supplyTypeDisplay(product);
          result.push(obj);
        });
      }

      return result;
    },
    async fetchAllData() {
      if (!this.isEmpty(this.contract.contractId)) {
        await this.getContract();
      }

      //GET SUPPLIER DATA
      if (this.suppliers.length < 1) {
        this.getSuppliers();
      }

      //GET ORGANISATION DATA
      this.organisation = Object.assign({}, Static.organisation);
      if (this.isEmpty(this.organisation) || Object.keys(this.organisation).length < 1) {
        let organisationId = !this.isEmpty(this.deal)
          ? this.deal.organisationId
          : this.contract.organisationId;

        await this.getOrganisation(organisationId);
      }

      //GET ACCOUNTANT DATA
      if (this.isEmpty(this.accountant) || Object.keys(this.accountant).length < 1) {
        let accountantId = !this.isEmpty(this.organisation)
          ? this.organisation.firstUserId
          : this.contract.accountantId;
        await this.getUser(accountantId);
      }
    },
    async getContract() {
      this.loadingContract = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}contracts/${this.contract.contractId}`
        );
        if (response.data.status === "success") {
          let resDates = response.data.contract.resignedDates
            ? [...response.data.contract.resignedDates]
            : [];
          let objDates = response.data.contract.objectionDates
            ? [...response.data.contract.objectionDates]
            : [];
          let docusiUrls = response.data.contract.docusignContractURL
            ? [...response.data.contract.docusignContractURL]
            : [];

          this.savedContract = Object.assign({}, response.data.contract);
          this.contract = Object.assign({}, response.data.contract);

          this.removeTimestamp();

          this.savedContract.resignedDates = resDates && resDates.length > 0 ? [...resDates] : [];
          this.savedContract.objectionDates = objDates && objDates.length > 0 ? [...objDates] : [];
          this.savedContract.docusignContractURL =
            docusiUrls && docusiUrls.length > 0 ? [...docusiUrls] : [];
        } else {
          this.showMessage("Sorry, there was a problem getting the contract.", "warning");
        }
      } catch {
        this.showMessage("Sorry, there was a problem getting the contract", "warning");
      } finally {
        this.loadingContract = false;
      }
    },

    async saveContract(evt) {
      evt.preventDefault();
      this.addTimestamp();
      this.addResignedDate();
      this.addObjectionDate();
      this.addDocusignContractURL();

      this.loadingContract = true;

      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}contracts`, {
          contract: this.contract
        });
        if (response.data.status === "success") {
          if (!this.contract.contractId) {
            let resDates = response.data.contract.resignedDates
              ? [...response.data.contract.resignedDates]
              : [];
            let objDates = response.data.contract.objectionDates
              ? [...response.data.contract.objectionDates]
              : [];
            let docusiUrls = response.data.contract.docusignContractURL
              ? [...response.data.contract.docusignContractURL]
              : [];

            this.savedContract = Object.assign({}, response.data.contract);
            this.contract = Object.assign({}, response.data.contract);
            this.removeTimestamp();

            this.savedContract.resignedDates = resDates ? [...resDates] : [];
            this.savedContract.objectionDates =
              objDates && objDates.length > 0 ? [...objDates] : [];
            this.savedContract.docusignContractURL = docusiUrls ? [...docusiUrls] : [];

            this.$emit("contractCreated");
          } else {
            this.$emit("contractUpdated", response.data.contract);
          }
          this.removeTimestamp();
          this.showMessage("Contract successfully updated.", "success");
        } else {
          this.showMessage("Sorry, there was a problem getting the contract.", "warning");
        }
      } catch {
        this.showMessage("Sorry, there was a problem getting the contract", "warning");
      } finally {
        this.hide();
        this.loadingContract = false;
      }
    },
    async getOrganisation(organisationId, autofill = false) {
      if (this.isEmpty(organisationId)) {
        return;
      }

      try {
        this.loadingOrganisation = true;
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        if (response.data.status == "success") {
          this.organisation = response.data.organisation;
          if (this.organisation.firstUserId) {
            await this.getUser(this.organisation.firstUserId);
          }

          if (autofill) {
            await this.fetchSalesRecord(organisationId);
            this.autofillOrganisationFields();
          }
        } else {
          this.orgIdError = true;
        }
      } catch {
        this.orgIdError = true;
      } finally {
        this.loadingOrganisation = false;
      }
    },
    async getUser(userId, autofill = false) {
      if (this.isEmpty(userId)) {
        return;
      }

      this.loadingUser = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}users/${userId}`);

        if (response.data.status == "success") {
          this.accountant = Object.assign({}, response.data.user);
          if (autofill) {
            this.autofillAccountantFields();
          }
        } else {
          this.accIdError = true;
        }
      } catch {
        this.accIdError = true;
      } finally {
        this.loadingUser = false;
      }
    },
    async getSuppliers() {
      this.loadingSuppliers = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(`${ApiHelper.endPoint()}suppliers`);

        if (response.data.status == "success") {
          this.suppliers = response.data.suppliers;
        } else {
          this.supplierError = true;
        }
      } catch {
        this.supplierError = true;
      } finally {
        this.loadingSuppliers = false;
      }
    },

    //FORMATTERS
    timestampFromDate(date) {
      if (!this.isEmpty(date)) {
        return date.substring(0, 10);
      } else {
        return date;
      }
    },

    addTimestamp() {
      //ADD TIMESTAMP from dates {T00:00 ..}
      for (let dateField of this.dateFields) {
        this.contract[dateField] = this.formatDateStringWithTime(this.contract[dateField]);
      }
    },
    removeTimestamp() {
      //REMOVE TIMESTAMP from dates {T00:00 ..}
      for (let dateField of this.dateFields) {
        if (!this.isEmpty(this.contract[dateField])) {
          this.contract[dateField] = this.timestampFromDate(this.contract[dateField]);
        }
      }
    },

    //RESIGNED date
    addResignedDate() {
      if (!this.contract.resignedDates || this.contract.resignedDates.length == 0) {
        this.contract.resignedDates = new Array();
      }

      this.newResignedDate = this.newResignedDate
        ? this.formatDateStringWithTime(this.newResignedDate).substring(0, 19)
        : null;
      if (this.newResignedDate && !this.contract.resignedDates.includes(this.newResignedDate)) {
        this.contract.resignedDates.push(this.newResignedDate);
        this.newResignedDate = null;
      }
    },
    removeResignedDate(index) {
      if (this.contract.resignedDates && this.contract.resignedDates.length > 0) {
        this.contract.resignedDates.splice(index, 1);
      }
    },

    // OBJECTION DATE
    addObjectionDate() {
      if (!this.contract.objectionDates || this.contract.objectionDates.length == 0) {
        this.contract.objectionDates = new Array();
      }

      this.newObjectionDate = this.newObjectionDate
        ? this.formatDateStringWithTime(this.newObjectionDate).substring(0, 19)
        : null;
      if (this.newObjectionDate && !this.contract.objectionDates.includes(this.newObjectionDate)) {
        this.contract.objectionDates.push(this.newObjectionDate);
        this.newObjectionDate = null;
      }
    },
    removeObjectionDate(index) {
      if (this.contract.objectionDates && this.contract.objectionDates.length > 0) {
        this.contract.objectionDates.splice(index, 1);
      }
    },

    //DOCUSIGN URL
    addDocusignContractURL() {
      if (!this.contract.docusignContractURL || this.contract.docusignContractURL.length == 0) {
        this.contract.docusignContractURL = new Array();
      }

      if (
        this.docusignContractURL &&
        !this.contract.docusignContractURL.includes(this.docusignContractURL)
      ) {
        this.contract.docusignContractURL.push(this.docusignContractURL);
        this.docusignContractURL = null;
      }
    },
    removeDocusignURL(index) {
      if (this.contract.docusignContractURL && this.contract.docusignContractURL.length > 0) {
        this.contract.docusignContractURL.splice(index, 1);
      }
    },

    //AUTOFILL
    autofillAllFields() {
      this.autofillOrganisationFields();
      this.autofillAccountantFields();
      this.autofillSupplyFields();
      this.autofillDealFields();

      this.stateChange();
      this.generateContractEndDate();
      this.generateSavingsOverContract();
    },
    autofillOrganisationFields() {
      if (!this.isEmpty(this.organisation) && Object.keys(this.organisation).length > 0) {
        this.contract.organisationId = this.organisation.organisationId;
        this.contract.organisationName = this.organisation.name;
        this.contract.organisationCreatedDate = this.organisation.createdDate
          ? this.organisation.createdDate.slice(0, 10)
          : null;
        this.contract.accountantUserId = this.organisation.firstUserId;
        this.contract.accountantEmail = this.organisation.firstUserEmail;

        if (!this.isEmpty(this.organisation.createdDate)) {
          this.contract.organisationCreatedDate = this.organisation.createdDate.slice(0, 10);
        }
      }
    },
    autofillAccountantFields() {
      if (!this.isEmpty(this.accountant) && Object.keys(this.accountant).length > 0) {
        this.contract.accountantUserId = this.accountant.userId;
        this.contract.accountantEmail = this.accountant.email;
        this.contract.accountantFirm = this.accountant.practiceName;

        if (!this.isEmpty(this.accountant.createdDate)) {
          this.contract.accountantSignUpDate = this.accountant.createdDate.slice(0, 10);
        }
      }
    },
    autofillSupplyFields() {
      if (!this.isEmpty(this.supply) && Object.keys(this.supply).length > 0) {
        this.contract.supplyName = this.supply.name;
        this.contract.meterNumber = this.supply.meterNumber;

        let consumption = this.supply.dayConsumption
          ? this.supply.dayConsumption
          : 0 + this.supply.nightConsumption
          ? this.supply.nightConsumption
          : 0 + this.supply.ewConsumption
          ? this.supply.ewConsumption
          : 0 + this.supply.winterConsumption
          ? this.supply.winterConsumption
          : 0 + this.supply.consumption
          ? this.supply.consumption // only for waterSupply
          : 0;

        if (consumption && consumption > 0) {
          this.contract.consumption = this.supply.dayConsumption;
        }

        if (this.supply.currentDeal) {
          this.contract.oldSupplierId = this.supply.currentDeal.supplierId;
          this.contract.oldSupplierName = this.supply.currentDeal.supplierName;
          this.contract.oldAnnualCost = this.supply.currentDeal.annualCost;

          this.contract.startDate = this.returnContractStartDate();
        }
      }
    },
    autofillDealFields() {
      if (!this.isEmpty(this.deal) && Object.keys(this.deal).length > 0) {
        this.contract.dealSortKey = this.deal.sortKey;
        this.contract.dealId = this.deal.dealId;
        this.contract.supplyId = this.deal.supplyId;
        this.contract.newSupplierId = this.deal.supplierId;
        this.contract.newSupplierName = this.deal.supplierName;
        this.contract.product = this.deal.type;

        this.contract.contractLength = this.deal.contractTerm;
        this.contract.newAnnualCost = this.deal.annualCost;
        this.contract.annualSavings = this.deal.saving;

        this.contract.commissionLevel = this.deal.commission;
        this.contract.commissionGross = this.deal.consumptionCommission;

        this.commissionGrossChange();
      }
    },
    autofillDealExtraFields(force = false) {
      if (!this.isEmpty(this.deal) && Object.keys(this.deal).length > 0) {
        this.contract.standingCharge = force
          ? this.deal.standingCharge
          : this.contract.standingCharge
          ? this.contract.standingCharge
          : this.deal.standingCharge;
        this.contract.dayRate = force
          ? this.deal.dayUnitRate
          : this.contract.dayRate
          ? this.contract.dayRate
          : this.deal.dayUnitRate;
        this.contract.nightRate = force
          ? this.deal.nightUnitRate
          : this.contract.nightRate
          ? this.contract.nightRate
          : this.deal.nightUnitRate;
        this.contract.ewRate = force
          ? this.deal.ewUnitRate
          : this.contract.ewRate
          ? this.contract.ewRate
          : this.deal.ewUnitRate;
        this.contract.winterRate = force
          ? this.deal.winterUnitRate
          : this.contract.winterRate
          ? this.contract.winterRate
          : this.deal.winterUnitRate;
      }
    },

    returnContractStartDate() {
      if (!this.isEmpty(this.deal)) {
        let today = new Date();
        let currEndDate = !this.isEmpty(this.supply.currentDeal.contractExpiry)
          ? new Date(this.supply.currentDeal.contractExpiry.slice(0, 10))
          : null;

        let dateFromToday_30days = new Date(new Date().setDate(new Date().getDate() + 30));
        let dateFromToday_1day = new Date(
          new Date().setDate(new Date().getDate() + 1)
        ).toISOString();

        let dateFromEndDate_1day = !this.isEmpty(this.supply.currentDeal.contractExpiry)
          ? new Date(
              new Date(this.supply.currentDeal.contractExpiry.slice(0, 10)).setDate(
                new Date(this.supply.currentDeal.contractExpiry.slice(0, 10)).getDate() + 1
              )
            ).toISOString()
          : null;

        //IF OOC ( or current end date before today)
        if ((currEndDate && currEndDate <= today) || this.supply.currentDeal.outOfContract6) {
          if (this.supply.currentDeal.supplierId != this.deal.supplierId) {
            return dateFromToday_30days.toISOString().slice(0, 10);
          } else {
            return dateFromToday_1day.slice(0, 10);
          }
          //IF NOT OOC
        } else if (
          currEndDate &&
          currEndDate < dateFromToday_30days &&
          this.supply.currentDeal.supplierId != this.deal.supplierId
        ) {
          return dateFromToday_30days.toISOString().slice(0, 10);
        } else {
          return dateFromEndDate_1day ? dateFromEndDate_1day.slice(0, 10) : null;
        }
      } else {
        return null;
      }
    },
    generateContractEndDate() {
      if (
        this.contract.startDate &&
        this.contract.startDate.length == 10 &&
        this.contract.contractLength
      ) {
        let startDate = new Date(this.contract.startDate);
        let endDate = new Date(
          startDate.setMonth(startDate.getUTCMonth() + Number(this.contract.contractLength))
        );

        this.contract.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() - 1))
          .toISOString()
          .slice(0, 10);
      }
    },
    generateSavingsOverContract() {
      let years = this.contract.contractLength ? Math.floor(this.contract.contractLength / 12) : 0;
      let months = this.contract.contractLength % 12;
      let savings = this.contract.annualSavings ? this.contract.annualSavings : 0;
      let result = savings * years + (savings / 12) * months;
      if (result) {
        this.contract.savingsOverContract = result;
      }
    },
    stateChange() {
      if (this.contract.deadDate) {
        this.contract.state = "DEAD";
      } else if (this.contract.lockedinDate) {
        this.contract.state = "LOCKED_IN";
      } else if (this.contract.failedDate) {
        this.contract.state = "FAILED";
      } else if (
        this.contract.signedDate ||
        (this.contract.resignedDates && this.contract.resignedDates.length > 0)
      ) {
        this.contract.state = "SIGNED";
      } else if (this.contract.outDate) {
        this.contract.state = "OUT";
      } else {
        this.contract.state = "NEW";
      }
    },
    commissionGrossChange() {
      this.contract.commissionNet =
        this.contract.commissionGross && this.commissionNetPercetage
          ? Number(this.contract.commissionGross * (this.commissionNetPercetage / 100)).toFixed(2)
          : null;
    },
    commissionNetChange() {
      this.commissionNetPercetage =
        this.contract.commissionNet && this.contract.commissionGross
          ? Number((this.contract.commissionNet * 100) / this.contract.commissionGross).toFixed(2)
          : null;
    },

    showMessage(message, type) {
      EventBus.$emit("show-toast", { message: message, variant: type });
    },

    setNewSupplierFields(supplierId) {
      this.contract.newSupplierName = this.suppliers[supplierId].name;
    },

    setCounterPartyFields(paymentFromId) {
      this.contract.paymentFromName = this.suppliers[paymentFromId].name;
    },

    async fetchCpmUsers() {
      this.cpmsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?cpmsOnly=true`)
        .then(response => {
          console.log("CPMs:", response);
          this.cpms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching CPM user data.", "warning");
        })
        .finally(() => (this.cpmsBusy = false));
    },

    async fetchApmUsers() {
      this.apmsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?apmsOnly=true`)
        .then(response => {
          console.log("APMs:", response);
          this.apms = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching APM user data.", "warning");
        })
        .finally(() => (this.apmsBusy = false));
    },

    async fetchOpsUsers() {
      this.opsBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?opsOnly=true`)
        .then(response => {
          console.log("Ops:", response);
          this.ops = response.data.users;
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching Ops user data.", "warning");
        })
        .finally(() => (this.opsBusy = false));
    },

    setCpmFields(cpmId) {
      this.cpms.forEach(cpm => {
        if (cpm.userId == cpmId) {
          this.contract.cpmEmail = cpm.email;
        }
      });
    },

    setApmFields(apmId) {
      this.apms.forEach(apm => {
        if (apm.userId == apmId) {
          this.contract.apmEmail = apm.email;
        }
      });
    },

    setOpsFields(opsId) {
      this.ops.forEach(op => {
        if (op.userId == opsId) {
          this.contract.opsEmail = op.email;
        }
      });
    },

    async fetchSalesRecord(orgId) {
      this.salesRecordBusy = true;
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}sales/records?organisationId=${orgId}`)
        .then(response => {
          console.log("Deal:", response);
          const salesRecord = response.data.salesRecords[0];
          if (salesRecord) {
            if (salesRecord.cpmId) {
              this.contract.cpmId = salesRecord.cpmId;
              this.contract.cpmEmail = salesRecord.cpmEmail;
            }
            if (salesRecord.apmId) {
              this.contract.apmId = salesRecord.apmId;
              this.contract.apmEmail = salesRecord.apmEmail;
            }
            if (salesRecord.opsId) {
              this.contract.opsId = salesRecord.opsId;
              this.contract.opsEmail = salesRecord.opsEmail;
            }
          }
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching deals data.", "warning");
        })
        .finally(() => (this.salesRecordBusy = false));
    }
  }
};
</script>
