class SupplyTypes {
  static supplyTypes() {
    let types = [];
    types.push("ELECTRICITY");
    types.push("GAS");
    types.push("WATER");
    types.push("CARD_PAYMENTS");
    types.push("TELECOMS");
    types.push("WASTE");
    types.push("FUEL");
    types.push("MOBILE");
    types.push("CUSTOM");
    return types;
  }

  static toDisplay(type) {
    var text;
    if (type) {
      switch (type) {
        case "EXAMPLE":
          text = "An Example";
          break;
        case "CUSTOM":
          text = "Other";
          break;
        default:
          text = type
            .toLowerCase()
            .split("_")
            .map(function (word) {
              return word[0].toUpperCase() + word.substr(1);
            })
            .join(" ");
      }
    }
    return text;
  }

  static toGuides(type) {
    let guideType = type.toLowerCase().replace("_", "-");
    if (type == "TELECOMS") {
      guideType = "business-broadband-and-telecoms";
    }
    if (type == "ELECTRICITY" || type == "GAS" || type == "WATER") {
      guideType = "business-" + guideType;
    }

    return guideType;
  }
}

export default SupplyTypes;
