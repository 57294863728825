/**
 * This class is used for setting static values that can be shared throughout SE.
 * The values are should be set once, are immutable (frozen) and can be accessed via class import.
 * This allows us to have a shared static store without having to pass data down via props.
 */
class StaticHelper {
  static organisation = {};

  static supplierOptionsMap = {}; // { SUPPLY_TYPE: [suppliers] }
  static handsetOptions = {};
  static customerOptions = [];
  static customerBankAccOptions = [];

  static docusignTemplates = {}; // {templateName: templateId}
  static busyDocusignTemplates = false;

  // CONTRACTS
  // supplyId { dealId: contractId, ...}
  // the DATA of ALL the contracts for each supply
  static contractIds = {};

  // {supplyId1: { state: state, dealExists: true, dealId: dealId, contractId: contractId }. supplyId2: {..}...}
  // the DATA of the LATEST contract for EACH supply
  static supplyContractStates = {};
  static busyContractIds = false;

  // list of dealIds. Loaded up with all the deals when opening savings editor
  // updated when deals are added or deleted
  static dealIds = [];

  static isInvalidBankAccount(obj) {
    if (typeof obj != "undefined" && 'sortCode' in obj && obj.sortCode != null && 'accountNumber' in obj && obj.accountNumber != null) {
      return obj.sortCode.match(/^[0-1]+$/) || obj.accountNumber.match(/^[0-1]+$/);
    } else {
      return false;
    }
  }

  static setSupplierOptions(suppliers) {
    if (suppliers) {
      this.supplierOptionsMap = {};

      Object.values(suppliers).forEach(s => {
        const option = {
          text: s.name,
          value: s.supplierId,
          logo: s.logos3url
        };
        if (s.categories) {
          s.categories.forEach(c => {
            if (this.supplierOptionsMap[c]) {
              this.supplierOptionsMap[c].push(option);
            } else {
              this.supplierOptionsMap[c] = [option];
            }
          });
        } else {
          console.warn("Supplier not included in supplier options - missing categories.", s);
        }
      });

      Object.freeze(this.supplierOptionsMap);
    }
  }

  static setHandsetOptions(handsets) {
    if (typeof handsets !== "undefined" && handsets && handsets.length > 0) {
      //SORTING BY BRANDNAME
      handsets.sort((a, b) => (a.brand.toLowerCase() > b.brand.toLowerCase() ? 1 : -1));

      //CREATING HANDSET MAP {brand: [handsets]}
      handsets.forEach(elem => {
        if (elem && elem.brand) {
          if (!this.handsetOptions[elem.brand]) {
            this.handsetOptions[elem.brand] = [];
          }

          this.handsetOptions[elem.brand].push(elem);
        }
      });
    }

    //SORTING BY MODEL NAME FOR EACH BRAND
    if (Object.keys(this.handsetOptions).length > 0) {
      Object.keys(this.handsetOptions).forEach(brand => {
        if (this.handsetOptions[brand].length > 0) {
          this.handsetOptions[brand].sort((a, b) =>
            a.model.toLowerCase() > b.model.toLowerCase() ? 1 : -1
          );
        }
      });
    }
  }

  static setOrganisation(organisation) {
    this.organisation = Object.assign({}, organisation);
    if (organisation && organisation.bankAccounts && organisation.bankAccounts.length > 0) {
      organisation.bankAccounts.forEach(banc => {
        if (!this.isInvalidBankAccount(banc)) {
          let obj = {};
          obj.text = banc.name + " " + banc.sortCode;
          obj.value = banc;
          this.customerBankAccOptions.push(obj);
        }
      })
    }

    Object.freeze(this.organisation);
  }

  static setCustomerOptions(sourceUsers) {
    if (sourceUsers && typeof sourceUsers !== "undefined" && sourceUsers.length > 0) {
      this.customerOptions = [];
      const validEmail = email => !email.includes("reducer.co.uk");

      for (let i = 0; i < sourceUsers.length; i++) {
        let user = sourceUsers[i];
        if (user.email && validEmail(user.email)) {
          let option = {};
          option.text = user.email;
          option.value = Object.assign({}, user);
          option.value.address = {};
          this.customerOptions.push(option);
        }
      }

      if (this.customerOptions.length > 0) {
        this.customerOptions.sort((a, b) =>
          a.text.toLowerCase() > b.text.toLowerCase()
            ? 1
            : b.text.toLowerCase() > a.text.toLowerCase()
              ? -1
              : 0
        );
      }

      Object.freeze(this.customerOptions);
    }
  }

  static setDocusignTemplates(dsTemplates) {
    if (dsTemplates && Object.keys(dsTemplates).length > 0) {
      this.docusignTemplates = Object.assign({}, dsTemplates);
    }
  }

  static setBusyDocusignTemplates(status) {
    this.busyDocusignTemplates = status;
  }

  static setDealIds(ids) {
    if (ids) {
      this.dealIds = [...ids];
    }
  }

  static setContractIds(contracts) {
    this.supplyContractStates = {}; // {supplyId1: { state: state, dealExists: true, dealId: dealId, contractId: contractId }. supplyId2: {..}...}

    if (contracts && Object.keys(contracts).length > 0 && this.dealIds && this.dealIds.length > 0) {
      let supplyIdLatestContract = {} // {supplyID: latest Contract};

      contracts.forEach(contract => {
        // { supplyId1 { dealId1: contractId } }
        if (!this.contractIds[contract.supplyId] && typeof this.contractIds[contract.supplyId] == 'undefined') {
          this.contractIds[contract.supplyId] = {}
        }

        this.contractIds[contract.supplyId][contract.dealId] = contract.contractId;

        if (!supplyIdLatestContract[contract.supplyId] || typeof supplyIdLatestContract[contract.supplyId] == undefined) {
          supplyIdLatestContract[contract.supplyId] = Object.assign({}, contract);
          this.supplyContractStates[contract.supplyId] = {};
          this.supplyContractStates[contract.supplyId].state = contract.state;
          this.supplyContractStates[contract.supplyId].dealExists = this.dealIds.includes(contract.dealId);
          this.supplyContractStates[contract.supplyId].dealId = contract.dealId;
          this.supplyContractStates[contract.supplyId].contractId = contract.contractId;
        } else {
          let savedDate = new Date(supplyIdLatestContract[contract.supplyId].createdDate);
          let compareDate = new Date(contract.createdDate);
          if (compareDate > savedDate) {
            supplyIdLatestContract[contract.supplyId] = Object.assign({}, contract);
            this.supplyContractStates[contract.supplyId] = {};
            this.supplyContractStates[contract.supplyId].state = contract.state;
            this.supplyContractStates[contract.supplyId].dealExists = this.dealIds.includes(contract.dealId);
            this.supplyContractStates[contract.supplyId].dealId = contract.dealId;
            this.supplyContractStates[contract.supplyId].contractId = contract.contractId;
          }
        }


      })
    }
  }

  static setBusyContractIds(status) {
    this.busyContractIds = status;
  }
}
export default StaticHelper;
