<template>
  <div id="info-row" v-if="contract && contract.contractId">
    <p class="m-0">
      <span class="color-legend orange" /> Orange underline shows that there is a difference between
      the <b>saved contract details</b> and the <b>current details of the deal</b>. To refresh the
      contact details, click the
      <i class="click-me-text" @click="$emit('autofill')">'Autofill'</i> button.
    </p>
    <p>
      <span class="color-legend blue" /> Blue underline shows that the highlighted value has not
      been saved yet. To save all changes, click
      <i class="click-me-text" @click="$emit('saveContract')">'Save'</i> button.
    </p>

    <div class="d-flex justify-content-between">
      <div>
        <p class="m-0"><b> Contract ID: </b> {{ contract.contractId }}</p>
        <p v-if="contract.createdDate" class="m-0">
          <b> Created Date: </b> {{ formatDate(contract.createdDate) }}
        </p>
        <p v-if="contract.createdByEmail" class="m-0">
          <b> Created By Email: </b> {{ contract.createdByEmail }}
        </p>
      </div>
      <div>
        <p v-if="contract.lastUpdatedDate" class="m-0">
          <b> Last Updated Date: </b> {{ formatDate(contract.lastUpdatedDate) }}
        </p>
        <p v-if="contract.lastUpdatedByUserId" class="m-0">
          <b> Last Updated By User ID: </b> {{ contract.lastUpdatedByUserId }}
        </p>
        <p v-if="contract.lastUpdatedByEmail" class="m-0">
          <b> Last Updated By Email: </b> {{ contract.lastUpdatedByEmail }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#info-row {
  background-color: $color-grey-lighter4;
  padding: 1rem;
  margin: -1.5rem -1.5rem 1rem -1.5rem;

  .color-legend {
    display: inline-block;
    margin-right: 0.5rem;
    width: 40px;
    height: 8px;
    border-radius: 5px;
    &.orange {
      background-color: $color-yellow-red2;
    }
    &.blue {
      background-color: $color-blue-main;
    }
  }

  .click-me-text {
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "ContractInfo",
  props: {
    contract: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDate: FormatHelper.formatDate
  }
};
</script>