<template>
  <div id="contract-comments" v-if="contractId">
    <p class="m-0">COMMENTS</p>

    <b-input-group class="mt-2">
      <b-textarea
        :disabled="busyComments"
        @keydown="commentEntered(newComment)"
        placeholder="Leave a comment by pressing enter"
        v-model="newComment.content"
      />
    </b-input-group>

    <p v-if="!comments || comments.length == 0" class="text-center m-0 mt-4">
      There are no comments on this contract
    </p>

    <b-row class="comment mx-1 my-3" v-else v-for="comment in comments" :key="comment.commentId">
      <b-button-group
        size="sm"
        v-if="comment.leftByUserId == adminId && !busyComments"
        class="actions-bar"
      >
        <b-button size="sm" variant="outline-secondary" @click="removeComment(comment)"
          ><font-awesome-icon :icon="['fas', 'trash']"
        /></b-button>
        <b-button
          size="sm"
          variant="outline-secondary"
          @click="editCommentId ? (editCommentId = null) : (editCommentId = comment.commentId)"
          ><font-awesome-icon :icon="['far', 'edit']"
        /></b-button>
      </b-button-group>

      <b-col cold="12" lg="5" xl="4" class="pre">
        <div>
          <b class="email m-0">{{ comment.leftByEmail }}</b>
          <p class="createdDate m-0">{{ formatDateTimeToSec(comment.createdDate) }}</p>
        </div>
      </b-col>
      <b-col class="d-flex flex-wrap align-items-center" cols="12" lg="7" xl="8">
        <b-textarea
          class="mt-3"
          v-if="editCommentId == comment.commentId"
          v-model="comment.content"
          @keydown="commentEntered(comment)"
        />
        <p v-else class="m-0 content">{{ comment.content }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#contract-comments {
  background-color: $color-grey-lighter4;
  padding: 1rem;
  margin: 0 -1.5rem 0 -1.5rem;

  & > .comment {
    background-color: white;
    border-radius: 10px;
    padding: 0.8rem;
    box-shadow: 0px 0px 5px rgba($color: $color-font-headings, $alpha: 0.3);
    position: relative;

    .actions-bar {
      position: absolute;
      top: 0;
      right: 0;
      & > button {
        padding: 2px 4px 2px 4px;
        & > svg {
          font-size: 13px;
        }
      }
    }

    .pre {
      @media screen and (min-width: 992px) {
        border-right: 1px solid $color-grey-lighter2;
      }

      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .email {
        font-size: 17px;
        color: $color-font-para;
      }

      .createdDate {
        font-size: 14px;
      }
    }

    .content {
      white-space: pre-wrap;
      max-width: 95%;
      color: $color-font-headings;
    }
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
export default {
  name: "ContractComments",
  props: {
    contractId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      adminId: null,
      comments: [],
      newComment: {
        content: null
      },
      editCommentId: null,
      busyComments: false
    };
  },
  created() {
    this.getAdmin();
    this.getContractComments();
  },
  methods: {
    formatDateTimeToSec: FormatHelper.formatDateTimeToSec,

    commentEntered(comment) {
      let evt = window.event;
      if (evt.keyCode == 13 && !evt.shiftKey) {
        this.saveComment(comment);
      }
    },

    async getAdmin() {
      var token = await CognitoAuth.getCurrentUserAccessToken();
      this.adminId = TokenHelper.parseJwtToken(token).sub;
    },
    async getContractComments() {
      this.busyComments = true;
      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}contracts/comments/${this.contractId}`
        );
        if (response.data.status === "success") {
          this.comments = response.data.comments;
        } else {
          this.showMessage("Sorry, there was a problem getting the contract comments.", "warning");
        }
      } catch {
        this.showMessage("Sorry, there was a problem getting the contract comments.", "warning");
      } finally {
        this.busyComments = false;
      }
    },
    async saveComment(comment) {
      this.editCommentId = null;
      comment.contractId = this.contractId;

      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}contracts/comment`, {
          comment: comment
        });
        if (response.data.status === "success") {
          this.newComment.content = null;
        } else {
          this.showMessage("Sorry, there was a problem saving the contract comment.", "warning");
        }
      } catch {
        this.showMessage("Sorry, there was a problem saving the contract comment.", "warning");
      } finally {
        this.getContractComments();
      }
    },
    async removeComment(comment) {
      try {
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}contracts/comment/${comment.commentId}`
        );
        if (response.data.status === "success") {
          this.getContractComments();
        } else {
          this.showMessage("Sorry, there was a problem deleting the contract comment.", "warning");
        }
      } catch {
        this.showMessage("Sorry, there was a problem deleting the contract comment.", "warning");
      }
    },

    showMessage(message, type) {
      EventBus.$emit("show-toast", { message: message, variant: type });
    }
  }
};
</script>